<template>
  <v-list-group
    :group="group"
    append-icon="mdi-menu-down"
  >
    <template v-slot:activator>
      <v-list-item-content
        class="text-start"
      >
        <v-icon
          v-if="drawer"
          v-html='item.meta.icon'
        />
        <v-list-item-title
          v-else
        >
          <v-icon
            v-if="item.meta.icon"
            class="mr-2"
            v-html='item.meta.icon'
          />
          {{item.title}}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <div v-if="!drawer">
      <template v-for="(child, i) in children">
        <nav-item
          v-if="child.meta.navItem"
          :key="`item-${i}`"
          :item="child"
          isChild
          text
        />
      </template>
    </div>
  </v-list-group>
</template>

<script>
import kebabCase from 'lodash/kebabCase'
import { mapState } from 'vuex'

export default {
  name: 'ItemGroup',

  inheritAttrs: false,
  components: {
    NavItem: () => import('./NavItem')
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        group: undefined,
        title: undefined,
        children: []
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['drawer']),
    children () {
      return this.item.children.map(item => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`
      }))
    },
    computedText () {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    group () {
      return this.genGroup(this.item.children)
    }
  },

  methods: {
    genGroup (children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group
          let group = `${parent}/${kebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`
          }

          return group
        }).join('|')
    }
  }
}
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
